<template>
  <div class="mains">
    <el-container>
      <el-header class="header">
        <headers @command="onCommand" />
      </el-header>

      <el-main class="main">
        <div class="tabs_bg">
          <div class="title">饭堂管理系统</div>

          <div class="tabs">
            <div class="tab_list">
              <el-menu class="tab_menu" :default-active="toIndex" text-color="#fff" active-text-color="#fff" background-color="transparent" mode="horizontal" @select="handleSelect">
                <el-menu-item class="tab_item" v-for="(item, index) in menuList" :key="index" :index="item.menuPath">{{ item.menuName }}</el-menu-item>
              </el-menu>
              <span v-if="false">
                <span class="more" v-if="!hiddenMore" @click="hiddenMore = !hiddenMore">
                  更多
                  <i class="el-icon-d-arrow-right"></i>
                </span>
                <span class="more" v-if="hiddenMore" @click="hiddenMore = !hiddenMore">
                  收起
                  <i class="el-icon-d-arrow-left"></i>
                </span>
              </span>
            </div>
            <transition mode="out-in" appear>
              <div class="moreTab" v-if="hiddenMore">
                <li v-for="(item, index) in menuList" :key="index" :class="{ activeBold: activeTab == item.menuPath }" @click="changeTab(item)">
                  {{ item.menuName }}
                </li>
              </div>
            </transition>
          </div>
        </div>
        <div class="views">
          <router-view />
        </div>
      </el-main>

      <!-- <div class="service" @click="contactService">
        <div>客服消息</div>
        <div class="msgtotal" v-if="msgtotal > 0">{{ msgtotal > 99 ? "99+" : msgtotal }}</div>
      </div> -->
    </el-container>

    <footers />

    <el-dialog class="dialog" :title="dialogTitle" :visible="dialogVisible" :close-on-click-modal="false" :before-close="handleClose">
      <div v-loading="loading">
        <el-tabs class="tabs_card" v-model="activeName" type="card" @tab-click="handleTabClick">
          <el-tab-pane label="个人信息" name="1">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
              <el-row>
                <el-col :span="12">
                  <el-form-item class="avatar_wrap" label="头像" prop="headPic">
                    <el-image class="avatar" :src="ruleForm.headPic" fit="cover"></el-image>
                    <el-upload class="upload_demo" accept=".png,.jpeg,.jpg" :file-list="fileList" :limit="1" name="multipartFile" :action="action" :on-success="handleSuccess" :before-upload="beforeUploads" :on-remove="handleRemove" list-type="picture" :show-file-list="false" :disabled="ruleForm.headPic ? true : false">
                      <el-button type="" size="mini" plain>修改头像</el-button>
                    </el-upload>
                    <i v-if="ruleForm.headPic" class="delpic el-icon-error" @click="handleRemove"></i>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="姓名" prop="userName">
                    <el-input v-model="ruleForm.userName" placeholder="请输入姓名"></el-input>
                  </el-form-item>
                  <el-form-item label="出生日期" prop="birthDate">
                    <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.birthDate" value-format="yyyy-MM-dd"></el-date-picker>
                  </el-form-item>
                  <el-form-item label="籍贯" prop="nativePlace">
                    <el-input v-model="ruleForm.nativePlace" placeholder="请输入籍贯"></el-input>
                  </el-form-item>
                  <el-form-item label="登录账号" prop="userAccount">
                    <el-input v-model="ruleForm.userAccount" placeholder="请输入登录账号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="性别" prop="userSex">
                    <el-select v-model="ruleForm.userSex" placeholder="请选择性别">
                      <el-option label="女" value="0"></el-option>
                      <el-option label="男" value="1"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="身份证" prop="identityCard">
                    <el-input v-model="ruleForm.identityCard" placeholder="请输入身份证"></el-input>
                  </el-form-item>
                  <el-form-item label="联系电话" prop="userPhone">
                    <el-input v-model="ruleForm.userPhone" placeholder="请输入联系电话"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="修改密码" name="2">
            <el-form :model="passForm" :rules="passRules" ref="passForm" label-width="150px" class="demo-passForm">
              <div class="pass_wrap">
                <el-form-item label="新的密码" prop="password">
                  <el-input v-model="passForm.password" placeholder="请输入新的密码" show-password></el-input>
                  <el-tooltip content="密码至少8位数且必须包含大写字母、小写字母、数字" placement="top" effect="light">
                    <i class="el-icon-warning-outline tips"></i>
                  </el-tooltip>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPassword">
                  <el-input v-model="passForm.confirmPassword" placeholder="请输入确认密码" show-password></el-input>
                </el-form-item>
              </div>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleConfirm(activeName == '1' ? 'ruleForm' : 'passForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TIM from "@tencentcloud/chat"
const options = {
  SDKAppID: process.env.NODE_ENV == "development" ? 1600043392 : 1600043510,
  debug: false,
}
function createInitRuleForm() {
  return {
    userName: "",
    userSex: "",
    birthDate: "",
    departmentId: "",
    identityCard: "",
    userPhone: "",
    nativePlace: "",
    userAccount: "",
    headPic: "",
    headPicId: "",
  }
}
export default {
  data() {
    // 正则表达式验证身份证的格式
    var validateIdCard = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("身份证不能为空"))
      }
      const idCardRegex = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}([0-9]|X)$/
      if (!idCardRegex.test(value)) {
        callback(new Error("身份证格式不正确"))
      } else {
        callback()
      }
    }
    var checkTel = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("联系电话不能为空"))
      }
      const telRegex = /^(?:(?:\+|00)86)?1\d{10}$/
      if (!telRegex.test(value)) {
        callback(new Error("联系电话格式不正确"))
      } else {
        callback()
      }
    }
    var checkTelReg = (rule, value, callback) => {
      const telRegex = /^(?:(?:\+|00)86)?1\d{10}$/
      if (!telRegex.test(value)) {
        callback(new Error("手机号码格式不正确"))
      } else {
        callback()
      }
    }
    return {
      activeTab: "/",
      menuList: [],
      hiddenMore: false,

      avatar: require("@/assets/img/avatar.png"),
      obj: {},
      activeName: "1",
      dialogTitle: "个人信息",
      dialogVisible: false,
      loading: false,
      fileList: [],
      action: this.$axios.defaults.baseURL + this.$api.uploadFile,
      ruleForm: createInitRuleForm(),
      passForm: { password: "", confirmPassword: "" },
      passRules: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 8,
            message: "密码格式不正确",
            validator: (rule, value, callback) => {
              if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(value)) {
                callback(new Error("密码必须包含大写字母、小写字母、数字"))
              } else {
                callback()
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        confirmPassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value !== this.passForm.password) {
                callback(new Error("两次输入的密码不一致!"))
              } else {
                callback()
              }
            },
            trigger: ["blur", "change"],
          },
        ],
      },
      rules: {
        userName: [{ required: true, message: "请输入姓名", trigger: "change" }],
        userSex: [{ required: true, message: "请选择性别", trigger: "change" }],
        birthDate: [{ required: true, message: "请选择日期", trigger: "change" }],
        nativePlace: [{ required: true, message: "请输入籍贯", trigger: "change" }],
        userPhone: [{ required: true, validator: checkTel, trigger: "change" }],
        identityCard: [{ required: true, validator: validateIdCard, trigger: "change" }],
        userAccount: [{ validator: checkTelReg, trigger: "blur" }],
      },

      // 客服消息数
      chat: null,
      msgtotal: 0,
      userID: "",
      userSig: "",
    }
  },
  created() {
    this.obj = this.$store.state.userInfo
    if (this.$store.state.menuList.length == 0) {
      this.$confirm("当前用户无权访问", "提示", {
        confirmButtonText: "确定",
        type: "error",
        showClose: false,
        closeOnClickModal: false,
        showCancelButton: false,
      }).then(() => {
        this.$router.replace("/login")
      })
    } else {
      this.menuList = this.$store.state.menuList
      // this.loginIM()
    }
  },
  computed: {
    // 根据路径绑定到对应的一级菜单，防止页面刷新重新跳回第一个
    toIndex() {
      if (this.$route.path == "/") {
        this.$router.replace(this.menuList[0].menuPath)
        return `${this.menuList[0].menuPath}`
      } else {
        return `/${this.$route.path.split("/")[1]}`
      }
    },
  },
  methods: {
    onCommand(command) {
      switch (command) {
        case "info":
          this.getInformation()
          this.dialogTitle = "个人信息"
          this.activeName = "1"
          this.dialogVisible = true
          break
        case "password":
          this.dialogTitle = "修改密码"
          this.activeName = "2"
          this.dialogVisible = true
          break
        case "exit":
          this.$confirm("即将退出当前账号, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              localStorage.removeItem("lastDetailPath")
              localStorage.clear()
              this.$store.dispatch("logout")
              this.$router.replace("/login")
            })
            .catch(() => {})
          break
      }
    },
    // 切换菜单
    handleSelect(index, path) {
      this.activeTab = path.join()
      this.$store.commit("setSideMenuActive", null)
      this.$store.commit("setIsDetail", null)
      localStorage.removeItem("lastDetailPath")
      this.$router.push({ path: path.join() })
    },
    // 切换菜单
    changeTab(e) {
      this.activeTab = e.path
      this.hiddenMore = false
      this.$router.push({ path: e.path })
    },
    // 打开聊天室
    contactService() {
      if (process.env.NODE_ENV === "production") {
        window.open(`https://consult.xiaoyuanshenghuowang.com/#/systems?type=${5}`) //type类型：1供应商；2商超；3骑手；4商城；5总后台
      } else {
        window.open(`http://192.168.2.6:8080/#/systems?type=${5}`) //type类型：1供应商；2商超；3骑手；4商城；5总后台
      }
    },
    // 个人信息
    getInformation() {
      this.loading = true
      this.$axios.get(this.$api.getInformation).then(res => {
        if (res.data.code == 100) {
          this.ruleForm = res.data.result
        }
        this.loading = false
      })
    },
    handleTabClick() {
      if (this.activeName == "1") {
        this.dialogTitle = "个人信息"
        this.getInformation()
      } else {
        this.dialogTitle = "修改密码"
      }
    },
    handleClose() {
      this.dialogTitle = null
      this.resetForm()
      this.dialogVisible = false
    },
    handleConfirm(formName) {
      let params = this.activeName == "1" ? Object.assign({}, this.ruleForm) : Object.assign({}, this.passForm)
      let api = this.activeName == "1" ? this.$api.updateInformation : this.$api.updatePassword
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios
            .post(api, params)
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success(res.data.desc)
                this.getInformation()
                if (this.activeName == "1") {
                  this.$store.dispatch("updateUserInfos", {
                    userName: params.userName,
                    userSex: params.userSex,
                    birthDate: params.birthDate,
                    departmentId: params.departmentId,
                    identityCard: params.identityCard,
                    userAccount: params.userAccount,
                    userPhone: params.userPhone,
                    nativePlace: params.nativePlace,
                    headPic: params.headPic,
                    headPicId: params.headPicId,
                  })
                  this.obj = this.$store.state.userInfo
                }
              }
              this.fileList = []
            })
            .catch(err => {
              this.fileList = []
              this.$message.error(err.data.desc)
            })
        } else {
          this.$message.error("提交失败")
          return false
        }
      })
    },
    // 重置校验
    resetForm() {
      if (this.activeName == "1") {
        this.ruleForm = createInitRuleForm()
        this.$refs.ruleForm.resetFields()
      } else {
        this.passForm = { password: "", confirmPassword: "" }
        this.$refs.passForm.resetFields()
      }
    },
    // 文件上传
    beforeUploads(file) {
      const isJPG = file.type === "image/jpeg" || "image/jpg" || "image/png"
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error("图文只支持jpg、png、jpeg，请调整格式再上传!")
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!")
      }
      return isJPG && isLt5M
    },
    // 删除文件
    handleRemove() {
      this.ruleForm.headPic = ""
      this.ruleForm.headPicId = ""
      this.fileList = []
    },
    // 上传成功
    handleSuccess(res) {
      if (res.code == 100) {
        this.ruleForm.headPicId = res.result.fileId
        this.ruleForm.headPic = res.result.fileUrl
      } else {
        this.$message({ showClose: true, message: res.desc, type: "error" })
      }
    },
    // 获取im登录账号
    loginIM() {
      this.$axios.get(this.$api.loginIM).then(res => {
        const { code, result } = res.data
        if (code == 100) {
          this.userID = result.accountId
          this.userSig = result.userSig
          this.initTIM()
        } else {
          this.$message.error("客服消息获取失败")
        }
      })
    },
    // 创建实例
    initTIM() {
      this.chat = TIM.create(options)
      // setLogLevel
      // 0 普通级别，日志量较多，接入时建议使用
      // 1 release级别，SDK 输出关键信息，生产环境时建议使用
      // 2 告警级别，SDK 只输出告警和错误级别的日志
      // 3 错误级别，SDK 只输出错误级别的日志
      // 4 无日志级别，SDK 将不打印任何日志
      // 官方文档：https://web.sdk.qcloud.com/im/doc/v3/zh-cn/SDK.html
      this.chat.setLogLevel(4)
      if (!this.chat) {
        this.$message.error("消息获取失败")
        return
      }
      this.loginTIM()
    },
    // 登录IM
    loginTIM() {
      let promise = this.chat.login({ userID: this.userID, userSig: this.userSig })
      promise
        .then(imResponse => {
          // 登录成功
          console.log("已登录")
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。
            console.log("🚀 ~ 重复登录")
          }
          // SDK 进入 ready 状态时触发，接入侧监听此事件，然后可调用 SDK 发送消息等 API，使用 SDK 的各项功能。
          this.chat.on(TIM.EVENT.SDK_READY, this.onSdkReady)
          // SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。如果想恢复使用，接入侧需调用 login 接口，驱动 SDK 进入 ready 状态。
          this.chat.on(TIM.EVENT.SDK_NOT_READY, this.onSdkNotReady)
          // 会话列表更新，event.data 是包含 Conversation 对象的数组
          this.chat.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, this.onConversationListUpdated)
          // 注册消息接收监听器
          this.chat.on(TIM.EVENT.MESSAGE_RECEIVED, () => {
            // console.log("收到新消息")
          })
        })
        .catch(imError => {
          // 登录失败的相关信息
          this.$store.commit("setIsLoggedIM", false)
          console.warn("登录失败:", imError)
        })
    },
    onSdkReady() {
      console.log("SDK已就绪")
    },
    onSdkNotReady() {
      this.loginTIM()
    },
    onConversationListUpdated() {
      // 包含 Conversation 实例的数组
      this.getIMConversationList()
    },
    // 获取全量的会话列表
    getIMConversationList() {
      this.chat
        .getConversationList()
        .then(imResponse => {
          // 全量的会话列表
          let count = imResponse.data.conversationList?.map(conversation => {
            return conversation.unreadCount || 0
          })
          this.msgtotal = count.reduce((a, b) => a + b, 0)
        })
        .catch(imError => {
          // 获取会话列表失败的相关信息
          console.warn("获取会话列表失败:", imError)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.mains {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    z-index: 10;
  }

  .main {
    height: 100%;
    min-height: 700px;
    padding: 0;

    .tabs_bg {
      position: fixed;
      top: 44px;
      left: 0;
      width: 100%;
      height: 130px;
      padding: 0 20px;
      background: linear-gradient(304deg, #e6d2d5 0%, #c27c88 100%);
      box-sizing: border-box;
      overflow: auto;
      z-index: 10;

      .title {
        width: 100%;
        height: 76px;
        line-height: 76px;
        color: #fff;
        font-size: 28px;
        font-family: "zzgf";
        text-align: center;

        img {
          width: 176px;
          height: 28px;
          -webkit-user-drag: none;
        }
      }

      .tabs {
        width: 100%;
        max-width: 1920px;
        min-width: 1360px;
        height: 44px;
        margin: 0 auto;

        .tab_list {
          position: relative;
          height: 44px;

          .tab_menu {
            display: flex;
            max-width: 100%;
            border: none;
          }

          .tab_item {
            position: relative;
            height: 44px;
            line-height: 44px;
            color: #707070;
            font-size: 16px;
            cursor: pointer;
          }

          .more {
            position: absolute;
            right: 0;
            bottom: 12px;
            color: #fff;
            cursor: pointer;
          }

          .is-active {
            color: #fff;
            font-weight: bold;
          }

          .el-menu--horizontal > .el-menu-item.is-active {
            border: none;
          }

          .is-active::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 6px;
            border-radius: 3px;
            background-color: #c27c88;
            transform: all 0.3s;
          }
        }

        .moreTab {
          position: absolute;
          top: 50px;
          display: flex;
          flex-wrap: wrap;
          width: 1800px;
          padding: 20px 20px 0;
          margin: 0 auto;
          border-radius: 10px;
          background-color: #fff;
          box-shadow: 0px 3px 6px rgba(159, 139, 119, 0.1);
          box-sizing: border-box;
          z-index: 10;

          li {
            color: #707070;
            font-size: 16px;
            margin-right: 26px;
            margin-bottom: 16px;
            list-style: none;
            cursor: pointer;
          }
        }

        .v-enter-active,
        .v-leave-active {
          transition: opacity 0.3s;
        }

        .v-enter,
        .v-leave-to {
          opacity: 0;
        }

        .activeBold {
          color: #c27c88 !important;
          font-weight: bold;
        }
      }
    }

    .views {
      width: 100%;
      max-width: 100%;
      padding-top: 174px;
      margin: 20px auto 20px;
      overflow: auto;
    }
  }

  .service {
    position: fixed;
    right: 1%;
    bottom: 3%;
    width: 15px;
    color: #fff;
    font-size: 12px;
    padding: 12px 8px;
    background-color: #c27c88;
    border-radius: 50px;
    cursor: pointer;
    z-index: 9999;

    .msgtotal {
      position: absolute;
      top: -8px;
      right: -8px;
      width: fit-content;
      background-color: #f56c6c;
      padding: 2px 6px;
      border-radius: 50px;
    }
  }

  .dialog {
    .tabs_card {
      ::v-deep .el-tabs__header {
        margin: 0 0 30px;
        border-bottom: none;
      }

      ::v-deep .el-tabs__nav {
        border: 1px solid #e4e7ed;
        border-radius: 10px;
        overflow: hidden;

        .is-active {
          color: #fff;
          background-color: #c27c88;
        }
      }

      ::v-deep .el-tabs__item {
        color: #606266;
        border-bottom: none;
        background-color: #fff;
        transition: none;
      }

      .avatar_wrap {
        position: relative;

        ::v-deep .el-form-item__label {
          height: 100px;
          line-height: 100px;
        }

        ::v-deep .el-form-item__content {
          display: flex;
          align-items: center;
          height: 100px;
        }

        .el-button {
          margin-left: 20px;
        }

        .avatar {
          display: block;
          width: 100px;
          height: 100px;
          border-radius: 50px;
        }

        .delpic {
          position: absolute;
          top: 0;
          right: 200px;
          color: red;
          font-size: 20px;
          cursor: pointer;
        }
      }

      .pass_wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 500px;
        margin: 0 auto;

        .tips {
          position: absolute;
          top: 50%;
          right: 70px;
          transform: translateY(-50%);
          color: #c0c4cc;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
